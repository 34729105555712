<template>
  <div class="mx-auto flex w-full max-w-screen-xl flex-col justify-center">
    <section
      class="my-4 grid grid-cols-2 place-items-center gap-6 px-4 md:grid-cols-4 lg:my-10"
    >
      <nuxt-link v-for="cat in categories" :key="cat.to" :to="cat.to">
        <ProductCategoryCard
          :title="cat.title"
          class="w-[150px] md:w-full md:max-w-[250px]"
        >
          <template #image>
            <img :src="cat.path" :alt="cat.alt" />
          </template>
        </ProductCategoryCard>
      </nuxt-link>
    </section>

    <LandingVisionHero />
    <LandingHighLights />
    <div class="mx-auto flex w-full flex-col gap-10 px-2 py-10">
      <LandingVisionBestSelling
        title="Bestselling Eyeglasses"
        category="bestselling"
      />
      <!-- we will use sunglasses as search query for testing in stg. Dev has dirty data and they might not show the correct images-->
      <LandingVisionBestSelling
        title="Bestselling Sunglasses"
        active-image="tinted"
        category="bestselling-sunglasses"
      />
      <div class="mt-10 max-w-7xl px-2 md:mx-auto">
        <h2 class="text-grays-darkest flex-1 text-2xl font-semibold leading-9">
          Shop by Frame Shape
        </h2>
        <div
          class="my-4 grid grid-cols-2 place-items-center gap-4 md:flex md:flex-wrap md:justify-center lg:my-10"
        >
          <ZnFrameStyleCard
            v-for="(style, index) in frameStyleCards.slice(0, 6)"
            :key="index"
            v-bind="style"
          />
        </div>
      </div>
      <ZnButton class="border-teal-dark mx-auto" type="secondary">
        <nuxt-link
          to="/products/list?Frame___Gender_search=unisex&Frame___Gender_search=men_s&Frame___Gender_search=women_s"
          >Shop all</nuxt-link
        >
      </ZnButton>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useEmployeeAlgolia, useNuxtApp, ref, useImage } from '#imports'
import type { ZnAlgoliaProduct } from '~/components/Zenni/Plp/algolia-types'

const { $shapeConfig } = useNuxtApp()
const img = useImage()
const shapeCategoryDefaultFilters: Record<string, string[]> = {
  cat_eye: ['women_s'],
  Cat__Eye: ['women_s'],
  Cat___Eye: ['women_s'],
}
const categories = [
  {
    to: '/products/list/women',
    path: '/img/landing/vision/category-women.png',
    title: "Women's Eyeglasses",
    alt: 'Woman wearing eyeglasses and a stethoscope.',
  },
  {
    to: '/products/list/men',
    path: '/img/landing/vision/category-men.png',
    title: "Men's Eyeglasses",
    alt: 'Man wearing eyeglasses and a hard hat.',
  },
  {
    to: '/products/list/kids',
    path: '/img/landing/vision/category-kids.png',
    title: "Kids' Eyeglasses",
    alt: 'Kids wearing eyeglasses.',
  },
  {
    to: '/products/list/sunglasses?activeImage=tinted',
    path: '/img/landing/vision/category-sunglasses.png',
    title: 'Sunglasses',
    alt: 'Woman wearing sunglasses.',
  },
]

const shapeProductsSearchParams = {
  query: '',
  requestOptions: {
    filters: 'objectType:"product-variant"',
    hitsPerPage: 10,
    facets: ['*'], // get all facets for filtering available frame styles in shop by frame style slider
  },
}

const frameStyleCards = ref<any[]>([])
const { search, result: data } = useEmployeeAlgolia<ZnAlgoliaProduct>()
await search(shapeProductsSearchParams)
const availableFrameShapes = Object.keys(
  data.value?.facets?.Frame___Shape_search || {},
)
$shapeConfig.forEach((shape) => {
  if (availableFrameShapes.includes(shape.code)) {
    const categoryUrl = `/products/list/?Frame___Shape_search=${
      shape.code
    }&Frame___Gender_search=${(
      shapeCategoryDefaultFilters[shape.code] ?? ['unisex', 'women_s', 'men_s']
    ).join('&Frame___Gender_search=')}`

    frameStyleCards.value.push({
      title: shape.values.label?.data || '',
      image: img(shape.values.imageurl?.data || '', {
        width: 115,
        height: 41,
      }),
      alt: shape.values.label?.data || '',
      href: categoryUrl,
    })
  }
})
</script>
