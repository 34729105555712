<template>
  <div
    class="bg-teal-lightest text-grays-dark grid grid-cols-2 gap-6 space-y-2 overflow-auto p-6 md:grid-cols-4 md:space-y-0"
  >
    <div
      class="mx-auto flex w-32 flex-col items-center space-y-2 text-center xl:w-auto xl:flex-row xl:space-x-2 xl:space-y-0"
    >
      <ZenniIconsIconGlasses size="base" class="text-grays-medium" />
      <span>Redeem glasses in 5 minutes!</span>
    </div>
    <div
      class="mx-auto flex w-32 flex-col items-center space-y-2 text-center xl:w-auto xl:flex-row xl:space-x-2 xl:space-y-0"
    >
      <ZenniIconsIconTruck size="base" class="text-grays-medium" />
      <span>Delivery in 7-10 business days</span>
    </div>
    <div
      class="mx-auto flex w-32 flex-col items-center space-y-2 text-center xl:w-auto xl:flex-row xl:space-x-2 xl:space-y-0"
    >
      <ZenniIconsIconUserHeadset size="base" class="text-grays-medium" />
      <span>24/7 customer service</span>
    </div>
    <div
      class="mx-auto flex w-32 flex-col items-center space-y-2 text-center xl:w-auto xl:flex-row xl:space-x-2 xl:space-y-0"
    >
      <ZenniIconsIconBoxOpen size="base" class="text-grays-medium" />
      <span>30-day returns</span>
    </div>
  </div>
</template>
